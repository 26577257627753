<template>
  <RevContainer>
    <RevBreadcrumb ariaLabel="test" :breads="[...homeRoutes, ...breads]" />
  </RevContainer>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed } from 'vue'

import type { ModelComparisonCategory } from '@backmarket/http-api/src/api-specs-model-comparison/models/categories'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import { RevContainer } from '@ds/components/Container'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'

import { HOME } from '../../../home/route-names'
import { MODELS_COMPARISON } from '../../routes-names'

import translations from './Breadcrumb.translations'

const i18n = useI18n()
const route = useRoute()

export type ModelComparisonBreadCrumb = {
  category?: ModelComparisonCategory
  modelsNames?: string[]
}

const props = withDefaults(defineProps<ModelComparisonBreadCrumb>(), {
  category: undefined,
  modelsNames: () => [],
})

const resolveLocalizedRoute = useRouteLocationWithLocale()

const homepageRoute = {
  link: resolveLocalizedRoute({ name: HOME }),
  title: i18n(translations.home),
}

const modelsComparisonHomeRoute = {
  link: resolveLocalizedRoute({ name: MODELS_COMPARISON.HOME }),
  title: i18n(translations.compare),
}

const homeRoutes = [homepageRoute, modelsComparisonHomeRoute]

const breads = computed(() => {
  if (typeof props.category !== 'undefined') {
    const { productA, productB, category } = route.params

    const categoryRoute = {
      link: resolveLocalizedRoute({
        name: MODELS_COMPARISON.CATEGORY,
        params: { category: props.category.name },
      }),
      title: props.category.label,
    }

    if (typeof productA === 'string' && typeof productB === 'string') {
      const comparisonResultsRoute = {
        link: resolveLocalizedRoute({
          name: MODELS_COMPARISON.COMPARISON_RESULTS,
          params: {
            productA,
            productB,
          },
        }),
        title: i18n(translations.modelAVsModelB, {
          modelA: props.modelsNames[0],
          modelB: props.modelsNames[1],
        }),
      }

      return [categoryRoute, comparisonResultsRoute]
    }

    if (typeof category === 'string') {
      return [categoryRoute]
    }
  }

  return []
})
</script>
