<template>
  <div v-if="hasSuggestedCategories">
    <h2 v-if="currentCategory" class="heading-2 mb-16">
      {{ i18n(translations.title) }}
    </h2>

    <RevCardGrid :cards="suggestedCategories">
      <template #default="{ card }: { card: ModelsComparisonCategory }">
        <RevCategoryCard
          v-bind="card"
          data-qa="selected-category"
          :tracking="{
            name: card.trackingName,
          }"
        />
      </template>
    </RevCardGrid>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCardGrid } from '@ds/components/CardGrid'
import { RevCategoryCard } from '@ds/components/CategoryCard'

import { useCurrentCategory } from '../../composables/useCurrentCategory'

import translations from './SuggestedCategories.translations'
import {
  type ModelsComparisonCategory,
  useFetchSuggestedCategories,
} from './useFetchSuggestedCategories'

const i18n = useI18n()
const { hasSuggestedCategories, suggestedCategories } =
  useFetchSuggestedCategories()
const currentCategory = useCurrentCategory()
</script>
