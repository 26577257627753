<template>
  <div>
    <Breadcrumb />

    <RevContainer class="bg-surface-default-low pb-56 md:pb-72">
      <h1 class="heading-1 mt-32 text-center">
        {{ i18n(translations.title) }}
      </h1>
      <div class="grid-standard">
        <SuggestedCategories
          class="mt-32 md:col-span-4 md:mt-56 lg:col-span-8 lg:col-start-3"
        />
      </div>
    </RevContainer>
  </div>
</template>

<script lang="ts" setup>
import { useAlternateLinks, useHead, useRoute } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'

import { getMetas } from '../../../utils/seo/meta'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb.vue'
import SuggestedCategories from '../components/SuggestedCategories/SuggestedCategories.vue'

import translations from './ModelsComparisonHome.translations'

const i18n = useI18n()
const { getAlternateLinks } = useAlternateLinks()
const route = useRoute()

const alternateLinks = getAlternateLinks({ link: route.path })

useHead({
  title: i18n(translations.metaTitle),
  link: alternateLinks,
  meta: getMetas({
    title: i18n(translations.metaTitle),
    description: i18n(translations.metaDescription),
  }),
})
</script>
