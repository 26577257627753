export default {
  home: {
    id: 'product_comparison_router_home',
    defaultMessage: 'Home',
  },
  compare: {
    id: 'product_comparison_router_compare',
    defaultMessage: 'Compare our products',
  },
  modelAVsModelB: {
    id: 'models_comparison_breadcrumb_comparison_results_label',
    defaultMessage: '{modelA} Vs {modelB}',
  },
} as const
