import { createHttpEndpoint } from '../utils'

import type { ModelComparisonCategories } from './models/categories'
import type { ModelComparisonCategoryContent } from './models/category-content'
import type { ModelComparisonCompareModels } from './models/compare-models'

export const getCategories = createHttpEndpoint<ModelComparisonCategories>({
  method: 'GET',
  operationId: 'getCategories',
  path: '/bm/models-comparison/categories',
})

export const getCategoryContent =
  createHttpEndpoint<ModelComparisonCategoryContent>({
    method: 'GET',
    operationId: 'getCategoryContent',
    path: '/bm/models-comparison/categories/:category',
  })

export const getModelsComparisonResults =
  createHttpEndpoint<ModelComparisonCompareModels>({
    method: 'GET',
    operationId: 'getModelsComparisonResults',
    path: '/bm/models-comparison/compare/:model1/:model2',
  })
