export default {
  title: {
    id: 'models_comparison_home_title',
    defaultMessage: 'Compare devices side by side',
  },

  metaTitle: {
    id: 'models_comparison_meta_title',
    defaultMessage: 'Compare our products',
  },

  metaDescription: {
    id: 'models_comparison_meta_description',
    defaultMessage: 'Compare our products',
  },
} as const
